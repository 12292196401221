import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  coupon_discount,
  getSelectedProgramPlan,
  MINIMUM_CHARGE,
  applyGift
} from "./utils";

const ProgramTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    console.log("plan", plan)
    const tuition_rate = parseFloat(plan.tuition_rate);
    const deposit = parseFloat(plan.deposit ? plan.deposit : 0);
    console.log("deposit", deposit)
    const taxRate = plan.isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage) / 100
      : 0;
    //const tax_amount = Math.max(MINIMUM_CHARGE, tuition_rate) * taxRate;
    const discount_amount = props.coupon
      ? coupon_discount(props.coupon, tuition_rate)
      : 0;
    const tax_amount =
      Math.max(0, tuition_rate - discount_amount) * taxRate;

    const creditCardPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    // const creditCardFee = props.program.transaction_cost
    //   ? Math.max(MINIMUM_CHARGE, tuition_rate - discount_amount) *
    //     creditCardPercent
    //   : 0;
    const creditCardFee = 0;

    let tot;
    let savedtot;
    let creditProcessingFee = 0;
    let creditDepositFee = 0;
    // if((!props.program.transaction_cost && props.program.application_required)){
    //   const creditPercent = parseFloat(3) / 100;
    //   creditProcessingFee = Math.max(MINIMUM_CHARGE, tuition_rate - discount_amount) * creditPercent;

    //   creditDepositFee = deposit * creditPercent;
    // }

    console.log("fees", {
      tuition_rate,
      discount_amount,
      tax_amount,
      creditCardFee,
      creditDepositFee,
      creditProcessingFee
    });

    if (
      new Date(props.paymentDate ? props.paymentDate : props.displayDate) >
        new Date() &&
      !props.isWaiveDeposit &&
      deposit !== 0
    ) {
      console.log("deposit block");
      tot = deposit + creditDepositFee;

      savedtot =
        Math.max(0, tuition_rate - discount_amount) +
        tax_amount +
        creditCardFee;
    } else if (props.isWaiveDeposit) {
      tot =
        Math.max(0, tuition_rate - discount_amount) +
        tax_amount +
        creditCardFee +
        creditDepositFee +
        creditProcessingFee;

      savedtot =
        Math.max(0, tuition_rate - discount_amount) +
        tax_amount +
        creditCardFee;
    } else {
      console.log("All fees");
      tot =
        Math.max(0, tuition_rate - discount_amount) +
        deposit +
        tax_amount +
        creditCardFee +
        creditDepositFee +
        creditProcessingFee;

      savedtot =
        Math.max(0, tuition_rate - discount_amount) +
        tax_amount +
        creditCardFee;
    }
    setPreGiftTotal(tot);
    // tot = giftBalance > tot ? 0 : tot - giftBalance;

    ({ amount: tot } = applyGift(
      props.program.manual_invoices,
      giftBalance,
      tot
    ));
    savedtot = giftBalance > tot ? 0 : savedtot - giftBalance;

    props.totalAmount(savedtot.toFixed(2));

    setTotal(tot.toFixed(2));
  };

  useEffect(() => {
    calculateTotal();
  }, [props.coupon, giftBalance]);

  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        fontSize: "14px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600,
          fontSize: "14px"
        }}
      >
        Total{" "}
        <span style={{ fontStyle: "italic", fontWeight: 500 }}>
          {new Date(props.paymentDate ? props.paymentDate : props.displayDate) >
          new Date()
            ? `(for today, billed on first day of enrollment)`
            : ""}
        </span>
      </p>
      <span style={{ fontWeight: "bold", fontSize: "14px" }}>${total}</span>
    </li>
  );
};

ProgramTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired,
  coupon: PropTypes.object
};

export default ProgramTotal;
