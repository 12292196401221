import React, { useEffect, useState, useGlobal } from "reactn";
import PropTypes from "prop-types";

import {
  getSelectedProgramPlan,
  isProgramPlanTaxEnabled,
  promotion_discount,
  PERCENT,
  FIXED_AMOUNT
} from "./utils";

const ProgramPromotionsTotal = props => {
  const [total, setTotal] = useState("Loading");
  const [_, setPreGiftTotal] = useGlobal("preGiftTotal");
  const giftBalance = useGlobal("giftBalance")[0];

  const calculateTotal = () => {
    const plan = getSelectedProgramPlan(props.program, props.selected);
    console.log("plan", plan);
    const tuition_rate = parseFloat(plan.tuition_rate);
    const discount_amount = promotion_discount(props.promotion, tuition_rate);
    console.log("discount_amount", discount_amount);
    const isTaxEnabled = isProgramPlanTaxEnabled(props.program, props.selected);
    const taxRate = isTaxEnabled
      ? parseFloat(plan.taxInfo[0].percentage) / 100
      : 0;

    const tax_amount = Math.max(0, tuition_rate - discount_amount) * taxRate;
    const deposit = parseFloat(plan.deposit ? plan.deposit : 0);
    console.log("deposit", deposit);
    const creditCardPercent = props.program.transaction_cost
      ? parseFloat(1.5) / 100
      : 1;
    const creditCardFee = props.program.transaction_cost
      ? Math.max(0.5, tuition_rate - discount_amount) * creditCardPercent
      : 0;
    // const creditCardFee = 0;

    let tot;
    let savedtot;

    if (
      new Date(props.displayDate) > new Date() &&
      deposit !== 0 /* ||
      props.program.application_required */
    ) {
      tot = deposit;
      console.log("tot", tot);
      /*       tot = Math.max(
        0.5,
        deposit + tuition_rate - discount_amount + tax_amount + creditCardFee
      );
      console.log("tot", tot) */
      savedtot = Math.max(
        0,
        tuition_rate - discount_amount + tax_amount + creditCardFee
      );
    } else if (
      props.promotion.discount_type === FIXED_AMOUNT &&
      isTaxEnabled === true
    ) {
      const tax_amount_FIX =
        Math.max(0, tuition_rate - discount_amount) * taxRate;
      const creditCardPercent_FIX = props.program.transaction_cost
        ? parseFloat(1.5) / 100
        : 1;
      console.log("creditCardPercent_FIX", creditCardPercent_FIX);
      const creditCardFee_FIX = props.program.transaction_cost
        ? Math.max(0.5, tuition_rate + tax_amount_FIX - discount_amount) *
          creditCardPercent_FIX
        : 0;
      console.log("breakdown", {
        deposit,
        tuition_rate,
        discount_amount,
        tax_amount_FIX,
        creditCardFee_FIX
      });
      tot = Math.max(
        0,
        deposit +
          tuition_rate -
          discount_amount +
          tax_amount_FIX +
          creditCardFee_FIX
      );

      savedtot = Math.max(
        0,
        tuition_rate - discount_amount + tax_amount_FIX + creditCardFee_FIX
      );

      if (tuition_rate - discount_amount === 0) {
        tot = 0;
        savedtot = 0;
      }
      console.log("tot 1", tot);
      console.log("savedtot 1", savedtot);
    } else if (
      props.promotion.discount_type === PERCENT &&
      isTaxEnabled === true
    ) {
      tot = Math.max(
        0,
        deposit + tuition_rate - discount_amount + tax_amount + creditCardFee
      );

      savedtot = Math.max(
        0,
        tuition_rate - discount_amount + tax_amount + creditCardFee
      );

      if (tuition_rate - discount_amount === 0) {
        tot = 0;
        savedtot = 0;
      }
      console.log("tot", tot);
      console.log("savedtot", savedtot);
    } else {
      tot = Math.max(
        0,
        deposit + tuition_rate - discount_amount + tax_amount + creditCardFee
      );

      savedtot = Math.max(
        0,
        tuition_rate - discount_amount + tax_amount + creditCardFee
      );

      if (tuition_rate - discount_amount === 0) {
        tot = 0;
        savedtot = 0;
      }
      console.log("tot", tot);
      console.log("savedtot", savedtot);
    }

    setPreGiftTotal(tot);
    tot = giftBalance > tot ? 0 : tot - giftBalance;
    savedtot = giftBalance > tot ? 0 : savedtot - giftBalance;
    console.log("tot 2", tot);
    console.log("savedtot 2", savedtot);
    props.totalAmount(savedtot.toFixed(2));
    if (tuition_rate - discount_amount === 0) {
      tot = 0;
      savedtot = 0;
    }

    setTotal(tot.toFixed(2));
  };

  useEffect(() => {
    calculateTotal();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px"
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 600,
          fontSize: "14px"
        }}
      >
        Total{" "}
        <span style={{ fontStyle: "italic", fontWeight: 500 }}>
          {new Date(props.displayDate) > new Date() ? "(for today, billed on first day of enrollment)" : ""}
        </span>
      </p>
      <span style={{ fontWeight: "bold", fontSize: "14px" }}>${total}</span>
    </li>
  );
};

ProgramPromotionsTotal.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  promotion: PropTypes.shape({
    discount_type: PropTypes.string.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramPromotionsTotal;
