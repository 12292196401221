import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { getSelectedProgramPlan } from "./utils";

const ProgramFreePlan = props => {
  const [fee, setFee] = useState("Loading");
  const plan = getSelectedProgramPlan(props.program, props.selected);
  const calculateFee = () => {
    setFee(parseFloat(plan.total_price).toFixed(2));
  };
  useEffect(() => {
    calculateFee();
  }, []);
  return (
    <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 10px",
        borderBottom: "1px solid #dedede"
      }}
    >
      <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <p
        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px"
        }}
      >
        Free Program
      </p>
      {plan.isBilledLater && <p        style={{
          marginBottom: 0,
          fontFamily: "Open Sans, sans-serif",
          fontWeight: 500,
          fontSize: "14px",
          fontStyle: "italic"
        }}
      > (Billed Later)</p>}
      </span>
      <span style={{ fontWeight: 500, fontSize: "14px" }}>${fee}</span>
    </li>
  );
};

ProgramFreePlan.propTypes = {
  program: PropTypes.shape({
    plans: PropTypes.array.isRequired
  }).isRequired,
  selected: PropTypes.number.isRequired
};

export default ProgramFreePlan;
